import React from 'react'
import { useInfiniteHits, useInstantSearch } from 'react-instantsearch-core'
import { ThreeColumnResultsHitWrapper } from '../styles'
import { Props } from '../types'

const InfiniteHits: React.FC<React.PropsWithChildren<Props>> = ({
  hitComponent: Hit,
  hitWrapperComponent: HitWrapper = ThreeColumnResultsHitWrapper,
  emptyComponent: Empty,
  skeletonLoader: SkeletonLoader,
  pageSize,
}) => {
  const { items } = useInfiniteHits()
  const { status } = useInstantSearch()

  if (status === 'loading' || status === 'stalled') {
    return (
      <HitWrapper key={`hits-infinite-loading-${status}`}>
        {new Array<number>(pageSize).map((index) => (
          <SkeletonLoader key={`hits-infinite-${index}`} />
        ))}
      </HitWrapper>
    )
  }

  if (items.length === 0) {
    return <div>{Empty}</div>
  }

  return (
    <HitWrapper>
      {items.map((hit) => (
        <Hit key={hit.objectID} id={hit.objectID} hit={hit} />
      ))}
    </HitWrapper>
  )
}

export default InfiniteHits
