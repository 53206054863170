import React from 'react'
import { styled } from 'styled-components'
import { theme } from '@damen/ui'

import { Referrals as ReferralsProps } from '@/queries/schema.generated'
import { Container } from '@/components/UI'
import ReferralsComponent from '@/components/Refferals'
import { VideoType } from '@/types'

const StyledContainer = styled(Container)`
  background-color: ${theme.colors.silverGrey100};
`

interface Props {
  content: ReferralsProps
}

const Referrals = ({ content }: Props) => {
  const { title, referrals, video } = content

  const mappedVideo = video ? { ...video, videoType: video.videoType as VideoType, image: { filename: video.image?.filename } } : null

  return (
    <StyledContainer>
      <ReferralsComponent
        title={title}
        video={mappedVideo}
        referrals={referrals}
      />
    </StyledContainer>
  )
}

export default Referrals
