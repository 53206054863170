import React from 'react'
import { styled } from 'styled-components'
import { theme } from '@damen/ui'

import { StandardProductCompare } from '@/queries/schema.generated'
import type { ModalProps } from '@/components/UI/Modal/types'
import { Container } from '@/components/UI'
import StandardProductCompareComponent from '@/components/StandardProductCompare'

const StyledContainer = styled(Container)`
  background: ${theme.colors.silverGrey100};
`

interface Props {
  content: StandardProductCompare
  setOpenModal: (props: ModalProps) => void
}

const StandardProductDetails = ({ content, setOpenModal }: Props) => {

  return (
    <StyledContainer>
      <StandardProductCompareComponent {...content} setOpenModal={setOpenModal} />
    </StyledContainer>
  )
}

export default StandardProductDetails
