import React from 'react'
import { PaginationProps as Props } from '../types'
import DefaultPagination from './Default'

const Pagination = ({ onPaginate, paginationMargin, paginationMarginMedia }: Props) => {
  return (
    <DefaultPagination
      onPaginate={onPaginate}
      paginationMargin={paginationMargin}
      paginationMarginMedia={paginationMarginMedia}
    />
  )
}
export default Pagination
