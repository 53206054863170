import { FunctionComponent } from 'react'
import dynamic from 'next/dynamic'

import AlgoliaInstockProductsOverview from '@/components/AlgoliaSearchOverview/instockproducts'
import AlgoliaUsedProductsOverview from '@/components/AlgoliaSearchOverview/usedproducts'
import AlgoliaCharterPRoductsOverview from '@/components/AlgoliaSearchOverview/charterproducts'
import AlgoliaProjectsOverview from '@/components/AlgoliaSearchOverview/projects'
import ShiprepairYardOverview from '@/components/AlgoliaSearchOverview/shiprepairyards'
import AlgoliaEventOverview from '@/components/AlgoliaSearchOverview/events'
import ArticleBody from '@/components/ArticleBody'
import { Articles, News } from '@/components/AlgoliaSearchOverview'
import Image from '@/components/UI/Image'
import Video from '@/components/UI/Video'
import ArticleFeed from '@/components/UI/ArticleFeed'
import ArticleSlider from '@/components/UI/ArticleSlider'
import Banner from '@/components/UI/Banner'
import BodyContent from '@/components/UI/BodyContent'
import Breadcrumb from '@/components/UI/Breadcrumb'
import Collage from '@/components/UI/Collage'
import CollageSecondary from '@/components/UI/CollageSecondary'
import CTA from '@/components/UI/CTA'
import DynamicImage from '@/components/UI/DynamicImage'
import Highlights from '@/components/UI/Highlights'
import FAQIconCards from '@/components/UI/FAQIconCards'
import FullscreenImageSingle from '@/components/UI/FullscreenImageSingle'
import LinkBlocks from '@/components/UI/LinkBlocks'
import MultiColumn from '@/components/UI/MultiColumn'
import Numbers from '@/components/UI/Numbers'
import ParallaxTiles from '@/components/UI/ParallaxTiles'
import StepByStep from '@/components/UI/StepByStep'
import Testimonials from '@/components/UI/Testimonials'
import TestimonialCards from '@/components/UI/TestimonialCards'
import TextWithImage from '@/components/UI/TextWithImage'
import TextWithVideo from '@/components/UI/TextWithVideo'
import Tiles from '@/components/UI/Tiles'
import VideoPanoramic from '@/components/UI/VideoPanoramic'
import ArticleStream from '@/components/UI/ArticleStream'
import StreamThumbnails from '@/components/UI/StreamThumbnails'
import Contact from '@/components/UI/Contact'
import ContactInfoCompany from '@/components/UI/ContactInfoCompany'
import Downloads from '@/components/UI/Downloads'
import EventDetails from '@/components/UI/EventDetails'
import EventFeed from '@/components/UI/EventFeed'
import FullscreenSlider from '@/components/UI/FullscreenSlider'
import Gallery from '@/components/UI/Gallery'
import Partners from '@/components/UI/Partners'
import PardotForm from '@/components/UI/PardotForm'
import ProductQuickView from '@/components/UI/ProductQuickView'
import ProductSlider from '@/components/UI/ProductSlider'
import ProjectDetails from '@/components/UI/ProjectDetails'
import QuickNav from '@/components/UI/QuickNav'
import ServiceBanner from '@/components/UI/ServiceBanner'
import Services from '@/components/UI/Services'
import StickyNavigation from '@/components/UI/StickyNavigation'
import Storytell from '@/components/UI/Storytell'
import Table from '@/components/UI/Table'
import TableComparison from '@/components/UI/TableComparison'
import TableWithRows from '@/components/UI/TableWithRows'
import Tabs from '@/components/UI/Tabs'
import TabsFamily from '@/components/UI/TabsFamily'
import ProductDetails from '@/components/UI/ProductDetails'
import ProductDetailCard from '@/components/UI/ProductDetailCard'
import VideoFeed from '@/components/UI/VideoFeed'
import FullWidthImage from '@/components/UI/FullWidthImage'
import BrandFeed from '@/components/UI/BrandFeed'
import CardFeedAlternative from '@/components/UI/CardFeedAlternative'
import ExpertiseSlider from '@/components/UI/ExpertiseSlider'
import IntegratedServices from '@/patterns/IntegratedServices'
import StandardProductDetails from '@/components/UI/StandardProductDetails'
import StandardProductPropositionHeader from '@/components/UI/StandardProductPropositionHeader'
import StandardProductCompare from './UI/StandardProductCompare'
import Referrals from '@/components/UI/Referrals'

// Header components
// TODO: Check all headers if the name is correct with Apollo
import HeaderError from '@/components/UI/HeaderError'
import HomeHeader from '@/components/UI/HomeHeader'
import PrimaryHeader from '@/components/UI/PrimaryHeader'
import SecondaryHeader from '@/components/UI/SecondaryHeader'
import VideoHeader from '@/components/UI/VideoHeader'

const LazyMap = dynamic(() => import('@/components/UI/Map/Global'))
const CampaignSmallForm = dynamic(() => import('@/components/UI/CampaignSmallForm'), { ssr: false })

// If a change is required here also check 'component/DeepDive/index.tsx'

// The mapping is using the principle: __typename: component
const Components = {
  ArticleFeed,
  ArticleSlider,
  Banner,
  BodyContent,
  Breadcrumb,
  Collage,
  CollageSecondary,
  CTA,
  DynamicImage,
  ExpertiseSlider,
  FaqIconCards: FAQIconCards,
  Highlights,
  ImageFullscreenSingle: FullscreenImageSingle,
  LinkBlocks,
  MapGlobal: LazyMap,
  MultiColumn,
  NewsFeed: ArticleFeed,
  Numbers,
  ParallaxTiles,
  StepByStep,
  Testimonials,
  TestimonialCards,
  TextWithImage,
  TextWithVideo,
  Tiles,
  VideoPanoramic,
  Image,
  Video,
  AlgoliaInStockProductOverview: AlgoliaInstockProductsOverview,
  AlgoliaUsedProductOverview: AlgoliaUsedProductsOverview,
  AlgoliaCharterProductOverview: AlgoliaCharterPRoductsOverview,
  AlgoliaProjectOverview: AlgoliaProjectsOverview,
  AlgoliaShiprepairYardOverview: ShiprepairYardOverview,
  AlgoliaEventOverview,
  Stream: ArticleStream,
  StreamThumbnails,
  CampaignSmallForm,
  Contacts: Contact,
  ContactInfoCompany,
  Downloads,
  EventDetails,
  EventFeed,
  FullscreenSlider,
  Gallery,
  Partners,
  PardotForm,
  ProductList: ProductQuickView,
  ProductQuickView,
  ProductSlider,
  ProjectDetails,
  QuickNav,
  ServiceBanner,
  Services,
  StickyNavigation,
  StoryTell: Storytell,
  StoryTellDeepDive: Storytell,
  Table,
  TableComparison,
  TableWithRows,
  Tabs,
  TabsFamily,
  UsedProductDetails: ProductDetails,
  ProductDetails: ProductDetailCard,
  StandardProductCompare,
  StandardProductDetails,
  StandardProductPropositionHeader,
  VideoFeed,
  ImageFullscreen: FullWidthImage,
  ArticleBody,
  AlgoliaArticleOverview: Articles,
  AlgoliaNewsOverview: News,
  BrandFeed,
  CardFeedAlternative,
  IntegratedServices,
  Referrals,

  // Header components
  HeaderError,
  HomeHeader,
  PrimaryHeader,
  SecondaryHeader,
  VideoHeader
} as Record<string, FunctionComponent<any>>

export default Components
