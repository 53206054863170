import React from 'react'
import { useHits } from 'react-instantsearch-core'
import { Title } from './styles'
import { ResultsTitleProps } from './types'

const ResultsTitle: React.FC<React.PropsWithChildren<ResultsTitleProps>> = ({ resultsText }) => {
  const { results } = useHits({})

  if (results.nbHits === 0) {
    return null
  }

  return (
    <Title>
      {results.nbHits} {resultsText} &quot;{results.query}&quot;
    </Title>
  )
}

export default ResultsTitle
