import React from 'react'
import { useHits, useInstantSearch } from 'react-instantsearch-core'
import { useMobile } from '@/hooks/mobile'
import { ThreeColumnResultsHitWrapper } from '../styles'
import { Props } from '../types'

const Hits: React.FC<React.PropsWithChildren<Props>> = ({
  hitComponent: Hit,
  emptyComponent: Empty,
  hitWrapperComponent: HitWrapper = ThreeColumnResultsHitWrapper,
  labelDefault,
  skeletonLoader: SkeletonLoader,
  pageSize = 8,
}) => {
  const { items } = useHits({})
  const { isMobile } = useMobile()
  const { status } = useInstantSearch()

  if (status === 'loading' || status === 'stalled') {
    return (
      <HitWrapper key={`hits-default-loading-${status}`}>
        {new Array<number>(pageSize).map((index) => (
          <SkeletonLoader key={`hits-default-${status}-${index}`} />
        ))}
      </HitWrapper>
    )
  }

  if (items.length === 0) {
    return <div>{Empty}</div>
  }

  return (
    <HitWrapper>
      {items.map((hit) => (
        <Hit key={hit.objectID} id={hit.objectID} hit={hit} isMobile={isMobile} labelDefault={labelDefault} />
      ))}
    </HitWrapper>
  )
}

export default Hits
