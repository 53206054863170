import React from 'react'
import styled, { css } from 'styled-components'
import { Heading, theme } from '@damen/ui'
import { Referrals as ReferralsProps } from '@/queries/schema.generated'
import { VideoPanoramic } from '../UI'
import { VideoType } from '@/types'
import ReferralCard from './components/referralCard'

const { mediaQueries, spacing } = theme

const Container = styled.div``

const StyledVideoPanoramic = styled(VideoPanoramic).withConfig({
  shouldForwardProp: (prop) => !['hasReferrals'].includes(prop),
})<{ hasReferrals?: boolean }>`
  z-index: 0;
  padding: 0;

  @media ${mediaQueries.mediaQueryDesktop} {
    width: ${({ hasReferrals }) => (hasReferrals ? '66.66%' : '100%')};
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${spacing.x5}px;

  @media ${mediaQueries.mediaQueryDesktop} {
    flex-direction: row;
    gap: ${spacing.x4}px;
  }

  @media ${mediaQueries.mediaQueryDesktopLarge} {
    gap: ${spacing.x13}px;
  }
`

const Title = styled(Heading.HeadingTwo)`
  margin-top: 0;
  margin-bottom: ${spacing.x3}px;
`

const ReferralsWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['hasVideo'].includes(prop),
})<{ hasVideo?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${spacing.x2}px;
  width: 100%;

  @media ${mediaQueries.mediaQueryTablet} {
    gap: ${spacing.x5}px;
  }

  @media ${mediaQueries.mediaQueryDesktop} {
    width: ${({ hasVideo }) => (hasVideo ? '33.33%' : '100%')};
  }

  ${({ hasVideo }) =>
    !hasVideo &&
    css`
      flex-direction: row;
      flex-wrap: wrap;

      > * {
        width: 100%;

        @media ${mediaQueries.mediaQueryTablet} {
          // we need to subtract the gaps from the total width
          // 2 columns with one gap between them
          // 40/2 = 20px
          width: calc(50% - 20px);
        }

        @media ${mediaQueries.mediaQueryDesktop} {
          // we need to subtract the gaps from the total width
          // 3 columns with two gaps between them
          // 80/3 = 26.666666666666668 so 27px
          width: calc(33.33% - 27px);
        }
      }
    `}
`

interface Props {
  title?: ReferralsProps['title']
  referrals?: ReferralsProps['referrals']
  video?: {
    image: {
      filename: ReferralsProps['video']['image']['filename']
    }
    videoId: ReferralsProps['video']['videoId']
    videoType: VideoType
  }
}

const Referrals: React.FC<Props> = ({ title, referrals, video }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <ContentWrapper>
        {referrals?.length > 0 && (
          <ReferralsWrapper hasVideo={!!video}>
            {referrals.map((referral, index) => (
              <React.Fragment key={index}>
                <ReferralCard {...referral} />
              </React.Fragment>
            ))}
          </ReferralsWrapper>
        )}
        {video && <StyledVideoPanoramic content={video} hasReferrals={referrals?.length > 0} />}
      </ContentWrapper>
    </Container>
  )
}

export default Referrals
