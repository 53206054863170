import { theme } from '@damen/ui'
import styled from 'styled-components'

export const ClearButton = styled.button`
  border: none;
  background: none;
  text-decoration: underline;
  margin: ${theme.layout.spacing};
  font-size: ${theme.typography.fontSizeLabel}px;
  color: ${theme.colors.marineBlack};
  cursor: pointer;
`
