import React from 'react'
import styled from 'styled-components'
import { NextImage, theme } from '@damen/ui'
import { Referral } from '@/queries/schema.generated'
import Link from '@/components/Link'

const { typography, spacing, fonts, colors, mediaQueries } = theme

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.x2}px;

  @media ${mediaQueries.mediaQueryTablet} {
    flex-direction: row;
  }
`

const Title = styled.h4`
  margin-top: ${spacing.x1}px;
  margin-bottom: 0;
  font-size: ${typography.fontSizeText}px;
  font-weight: ${typography.fontWeightMedium};
  line-height: ${typography.lineHeightTextXSmall};

  @media ${mediaQueries.mediaQueryTablet} {
    margin-top: 0;
  }
`

const Label = styled.span`
  margin-bottom: ${spacing.x1}px;
  font-family: ${fonts.overline.style.fontFamily};
  font-size: ${typography.fontSizeTextSmall}px;
  font-weight: ${typography.fontWeightBold};
  text-transform: uppercase;
  letter-spacing: 0.6px;
  color: ${colors.grey};
`

const ImageWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  aspect-ratio: 343 / 248;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: transform 0.5s ease-in-out;
  }

  @media ${mediaQueries.mediaQueryTablet} {
    width: 25%;
    min-width: 25%;
  }

  @media ${mediaQueries.mediaQueryDesktop} {
    width: 33%;
    min-width: 33%;
  }
`

const StyledLink = styled(Link.VNext)`
  &:hover,
  &:focus {
    ${ImageWrapper} {
      img {
        transform: scale(1.10);
      }
    }
  }
`

const ReferralCard: React.FC<Referral> = ({ title, link, image, label }) => {
  return (
    <StyledLink type="link" link={link}>
      <Content>
        <ImageWrapper>
          <NextImage src={image?.filename} alt={image?.alt} aspectRatio="343:248" width={343} height={248} />
        </ImageWrapper>
        <div>
          {label && <Label>{label}</Label>}
          <Title>{title}</Title>
        </div>
      </Content>
    </StyledLink>
  )
}

export default ReferralCard
