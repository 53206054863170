import React from 'react'
import ReferralsPattern from '@/patterns/Referrals'
import { Referrals as ReferralsProps } from '@/queries/schema.generated'

// TODO This should be moved to a bloks folder where all patterns are present.
// The components folder should only contain components (like buttons) that are used in patterns.

interface Props {
  content: ReferralsProps
}

const Referrals: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  return <ReferralsPattern content={content} />
}

export default Referrals
