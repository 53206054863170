import React from 'react'
import { ClearButton } from './styles'
import { useClearRefinements } from 'react-instantsearch-core'

const CurrentRefinementList: React.FC<React.PropsWithChildren> = () => {
  const { refine } = useClearRefinements()

  return <ClearButton onClick={refine}>Clear</ClearButton>
}

export default CurrentRefinementList
