import React from 'react'
import { Props } from './types'
import { ResultSpan, StyledHeading, Wrapper } from './styles'
import { useStats } from 'react-instantsearch-core'

const OverviewTitle: React.FC<Props> = ({ title }) => {
  const { nbHits: resultCount } = useStats({})
  if (!title) {
    return null
  }

  const showResults = title.totalResultsText?.length > 0

  const resultsText = showResults ? `(${resultCount} ${title.totalResultsText})` : ''

  return (
    <Wrapper>
      <StyledHeading>{title.title}</StyledHeading>
      {showResults && <ResultSpan>{resultsText}</ResultSpan>}
    </Wrapper>
  )
}

export default OverviewTitle
