import React, { MutableRefObject, useRef } from 'react'
import InstantSearch from './InstantSearch'
import { Container } from '../UI'
import Configure from './Configuration'
import CurrentRefinementList from './CurrentRefinementList'
import EmptyResults from './Empty'
import RefinementList from './RefinmentList'
import type { Props } from './types'
import { OffsetElement, ResultsContainerDefault, OverviewContainerDefault } from './styles'
import ResultsTitle from './ResultsTitle'
import Pagination from './Pagination'
import { Hits, InfiniteHits } from './Hits'
import LoadMore from './LoadMore'
import OverviewTitle from './OverviewTitle'

const onPaginate = (componentRef: MutableRefObject<HTMLDivElement>) => {
  setTimeout(() => {
    componentRef.current.scrollIntoView({
      behavior: 'smooth',
    })
  }, 500)
}

const AlgoliaSearchOverview: React.FC<React.PropsWithChildren<Props>> = ({
  pageType = 'default',
  hitWrapperComponent: HitWrapper,
  hitComponent: Hit,
  overviewContainerComponent: OverviewContainer = OverviewContainerDefault,
  resultsContainerComponent: ResultsContainer = ResultsContainerDefault,
  rightComponent: RightComponent,
  facets,
  // Set default pageSize here too, used for loading state
  pageSize = 9,
  indexName,
  enableSearchbox = true,
  searchBoxPlaceholder,
  resultsPlaceholder,
  mobileFiltersTitle,
  noResultsContent,
  emptyComponent: Empty,
  pager: loadType = 'pagination',
  loadMoreText,
  defaultSearchOptions,
  title,
  resultsText = 'Results for',
  refinementComponentType = 'dropdown',
  enableResultsTitle = false,
  currentRefinementFiltersWrapper,
  refinementListDisplayOption = 'horizontal',
  paginationMargin = '80px 0',
  paginationMarginMedia = '116px 0',
  enableEmptyCheckForTotalHits = false,
  enableFilterForMediaButton = false,
  mainContainerCssPosition = 'relative',
  showCurrentRefinementResultCount = true,
  keepCurrentRefinementListSpaceWhenCountIsZero = true,
  sortByOptions = [],
  sortByOptions4thSpecification = [],
  sortByPlaceholder,
  initialSort,
  cmsSortOrder,
  skeletonLoader,
}) => {
  const offsetRef = useRef<HTMLDivElement>(null)

  return (
    <Container pageType={pageType} cssPosition={mainContainerCssPosition}>
      <InstantSearch indexName={indexName}>
        <OverviewContainer>
          <OverviewTitle title={title} />
          <Configure hitsPerPage={pageSize} defaultSearchOptions={defaultSearchOptions} />
          <OffsetElement ref={offsetRef} />
          <RefinementList
            facets={facets}
            refinementComponentType={refinementComponentType}
            enableSearchbox={enableSearchbox}
            searchBoxPlaceholder={searchBoxPlaceholder}
            rightComponent={RightComponent}
            refinementListDisplayOption={refinementListDisplayOption}
            enableEmptyCheckForTotalHits={enableEmptyCheckForTotalHits}
            enableFilterButton={enableFilterForMediaButton}
            sortByOptions={sortByOptions}
            sortByOptions4thSpecification={sortByOptions4thSpecification}
            sortByPlaceholder={sortByPlaceholder}
            initialSort={initialSort}
            mobileFiltersTitle={mobileFiltersTitle}
          />

          <ResultsContainer>
            {enableResultsTitle && <ResultsTitle resultsText={resultsText} />}
            <CurrentRefinementList
              resultsPlaceholder={resultsPlaceholder}
              facets={facets}
              filtersWrapper={currentRefinementFiltersWrapper}
              showResultCount={showCurrentRefinementResultCount}
              keepSpaceWhenCountIsZero={keepCurrentRefinementListSpaceWhenCountIsZero}
            />
            <>
              {loadType === 'pagination' && (
                <>
                  <Hits
                    key={`${indexName}-hits`}
                    hitComponent={Hit}
                    emptyComponent={
                      Empty ?? (
                        <EmptyResults
                          key={`${indexName}-emptyContainer`}
                          title={noResultsContent.title}
                          text={noResultsContent.text}
                        />
                      )
                    }
                    hitWrapperComponent={HitWrapper}
                    cmsSortOrder={cmsSortOrder}
                    skeletonLoader={skeletonLoader}
                    pageSize={pageSize}
                  />

                  <Pagination
                    onPaginate={() => onPaginate(offsetRef)}
                    paginationMargin={paginationMargin}
                    paginationMarginMedia={paginationMarginMedia}
                  />
                </>
              )}
              {loadType !== 'pagination' && (
                <>
                  <InfiniteHits
                    hitComponent={Hit}
                    emptyComponent={Empty}
                    hitWrapperComponent={HitWrapper}
                    skeletonLoader={skeletonLoader}
                    pageSize={pageSize}
                  />
                  <LoadMore loadMoreText={loadMoreText} />
                </>
              )}
            </>
          </ResultsContainer>
        </OverviewContainer>
      </InstantSearch>
    </Container>
  )
}

export default AlgoliaSearchOverview
