import { Icon } from '@damen/ui'
import React from 'react'
import { useCurrentRefinements, useStats } from 'react-instantsearch-core'
import { FilterButton, Label, ResultsText, SelectedFiltersWrapper, SelectedFilters } from './styles'
import { Props } from './types'
import ClearRefinements from '../ClearRefinements'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const translateLabel = (refinement: any, booleanFacet: any): string => {
  const labelTrue = booleanFacet?.labelTrue
  const labelFalse = booleanFacet?.labelFalse
  if (refinement.type === 'numeric') {
    return `${booleanFacet.label} ${refinement.label} ${booleanFacet?.unit}`
  }
  switch (refinement.label) {
    case 'true':
      if (labelTrue) {
        return labelTrue
      }
      return refinement.label

    case 'false':
      if (labelFalse) {
        return labelFalse
      }
      return refinement.label

    default:
      return refinement.label
  }
}

const CurrentRefinementList: React.FC<React.PropsWithChildren<Props>> = ({
  resultsPlaceholder,
  facets,
  filtersWrapper: FiltersWrapper = SelectedFiltersWrapper,
  showResultCount = true,
}) => {
  const { items, refine } = useCurrentRefinements()
  const { nbHits } = useStats()

  return (
    <FiltersWrapper>
      {showResultCount && items.length > 0 && (
        <ResultsText>
          {nbHits} {resultsPlaceholder}
        </ResultsText>
      )}
      <SelectedFilters>
        {items.length > 0 && (
          <>
            {items.map((facet) =>
              facet.refinements.map((refinement) => {
                const booleanFacet = facets?.find((facet) => facet.type === refinement.attribute)
                const translatedLabel = translateLabel(refinement, booleanFacet)

                return (
                  <FilterButton key={`${facet.attribute}${refinement.value}`} onClick={() => refine(refinement)}>
                    <Icon.Close width={10} height={10} />
                    <Label>{translatedLabel}</Label>
                  </FilterButton>
                )
              }),
            )}

            <ClearRefinements />
          </>
        )}
      </SelectedFilters>
    </FiltersWrapper>
  )
}

export default CurrentRefinementList
