import React from 'react'
import RefinementDropdown from './dropdown'
import RefinementRangeDropdown from './rangeDropdown'
import RefinementCheckbox from './checkbox'
import { RefinementProps } from './types'

const Refinement: React.FC<React.PropsWithChildren<RefinementProps>> = ({ facets, refinementComponentType }) => {
  return (
    <>
      {facets.map((facet) => (
        <>
          {(refinementComponentType === 'dropdown' && facet.filterType === 'equalOrGreaterThan') && (
            <RefinementRangeDropdown
              key={facet.type}
              type={facet.type}
              label={facet.label}
              unit={facet.unit}
            />
          )}
          {(refinementComponentType === 'dropdown' && facet.filterType !== 'equalOrGreaterThan') && (
            <RefinementDropdown
              key={facet.type}
              type={facet.type}
              label={facet.label}
              trueTranslation={facet.labelTrue}
              falseTranslation={facet.labelFalse}
            />
          )}
          {refinementComponentType === 'checkbox' && (
            <RefinementCheckbox
              key={facet.type}
              type={facet.type}
              label={facet.label}
              trueTranslation={facet.labelTrue}
              falseTranslation={facet.labelFalse}
              showFalseValue={facet.showFalseValue}
              expandText={facet.expandText}
              collapseText={facet.collapseText}
            />
          )}
        </>
      ))}
    </>
  )
}

export default Refinement
