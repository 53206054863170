import React from 'react'
import { styled } from 'styled-components'
import { theme } from '@damen/ui'

import { Container } from '@/components/UI'
import StandardProductPropositionHeaderComponent from '@/components/StandardProductPropositionHeader'
import { ModalProps } from '@/components/UI/Modal/types'
import { StandardProductPropositionHeaderContentProps } from '@/components/UI/StandardProductPropositionHeader'

const StyledContainer = styled(Container)`
  background-color: ${theme.colors.silverGrey100};
  padding-top: 8px;
  padding-bottom: 8px;
`

interface Props {
  content: StandardProductPropositionHeaderContentProps
  setOpenModal: (props: ModalProps) => void
}

const StandardProductPropositionHeader = ({ content, setOpenModal }: Props) => {
  const { propositions, propositionHeaderLink, propositionHeaderLinkText, product, overviewSectionId } = content

  const mappedPropositions = React.useMemo(() => {
    return propositions.map((proposition) => {
      return {
        title: proposition.title,
        link: proposition.propositionLink,
        id: proposition.id,
      }
    })
  }, [propositions])

  return (
    <StyledContainer>
      <StandardProductPropositionHeaderComponent
        link={propositionHeaderLink}
        linkText={propositionHeaderLinkText}
        propositions={mappedPropositions}
        product={product}
        overviewSectionId={overviewSectionId}
        setOpenModal={setOpenModal}
      />
    </StyledContainer>
  )
}

export default StandardProductPropositionHeader
