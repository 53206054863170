import React from 'react'
import styled from 'styled-components'
import { Heading, theme } from '@damen/ui'
import { ModalProps } from '../UI/Modal/types'
import { StandardProductCompare as StandardProductCompareProps } from '@/queries/schema.generated'
import { useRouter } from 'next/router'
import Link from '../Link'
import DownloadProductSheet from './components/downloadProductSheet'

const { colors, typography, spacing, mediaQueries } = theme

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.x2}px;

  @media ${mediaQueries.mediaQueryDesktop} {
    gap: ${spacing.x3}px;
  }

  @media ${mediaQueries.mediaQueryDesktopLarge} {
    gap: ${spacing.x4}px;
  }
`

const Title = styled(Heading.HeadingTwo)`
  margin-top: 0;
  margin-bottom: ${spacing.x4}px;
`

const DesktopTableColumn = styled.td`
  padding: ${spacing.x3}px ${spacing.x2}px;
  background: ${colors.blueIce};
  font-weight: ${typography.fontWeightMedium};
`

const DesktopTableHighlightColumn = styled.td`
  padding: ${spacing.x2}px ${spacing.x1}px;
  background: ${colors.white};
  font-weight: ${typography.fontWeightMedium};
  text-align: center;
`

const DesktopTableHeading = styled.th`
  font-weight: ${typography.fontWeightRegular};
  color: ${colors.marineBlack};
  text-align: center;
  vertical-align: top;

  @media ${mediaQueries.mediaQueryDesktopLarge} {
    width: 124px;

    &:first-of-type {
      width: 216px;
    }

    &:last-of-type {
      width: 227px;
    }
  }
`

const DesktopTable = styled.table`
  display: none;

  @media ${mediaQueries.mediaQueryDesktop} {
    display: table;
    border-spacing: ${spacing.x2}px;
    margin-left: -${spacing.x2}px;
    margin-right: -${spacing.x2}px;

    tr:first-of-type {
      td:first-of-type {
        color: ${colors.blue};
      }
    }
  }
`

const MobileColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.x2}px;
  width: 200px;
  min-width: 200px;

  &:not(:first-of-type) {
    dt {
      opacity: 0;
    }
  }
`

const MobileColumnHeader = styled.div`
  padding: ${spacing.x3}px ${spacing.x1}px;
  background: ${colors.blueIce};
  font-size: ${typography.fontSizeTextSmall}px;
  font-weight: ${typography.fontWeightMedium};
  text-align: center;
`

const MobileContainer = styled.div`
  display: flex;
  gap: ${spacing.x2}px;
  padding-bottom: ${spacing.x2}px;
  overflow-x: auto;

  ${MobileColumn} {
    &:first-of-type {
      ${MobileColumnHeader} {
        color: ${colors.blue};
      }
    }
  }

  @media ${mediaQueries.mediaQueryTablet} {
    gap: ${spacing.x4}px;
  }

  @media ${mediaQueries.mediaQueryDesktop} {
    display: none;
  }
`

const MobileDatalist = styled.dl``

const MobileDatalistTitle = styled.dt`
  min-height: 18px;
  margin-bottom: ${spacing.x05}px;
`

const MobileDatalistDescription = styled.dd`
  min-height: 66.36px;
  margin: 0;
  padding: ${spacing.x3}px ${spacing.x1}px;
  background: ${colors.white};
  font-weight: ${typography.fontWeightMedium};
  text-align: center;

  &:not(:last-of-type) {
    margin-bottom: ${spacing.x1}px;
  }
`

const StyledLink = styled(Link.VNext)`
  transition: color 0.2s ease-in-out;

  &:hover,
  &:focus {
    color: ${colors.blue};
    text-decoration: underline;
  }
`

interface Props {
  setOpenModal: (props: ModalProps) => void
}

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
const StandardProductCompare: React.FC<StandardProductCompareProps & Props> = ({
  title,
  compareItems,
  downloadText,
  setOpenModal,
}) => {
  const router = useRouter()

  return (
    <Container>
      <Title>{title}</Title>
      <DesktopTable>
        <thead>
          <tr>
            <DesktopTableHeading></DesktopTableHeading>
            {compareItems[0].highlights.map((highlight) => (
              <DesktopTableHeading key={highlight.id}>{highlight.label}</DesktopTableHeading>
            ))}
            <DesktopTableHeading></DesktopTableHeading>
          </tr>
        </thead>
        <tbody>
          {compareItems.map((item, index) => (
            <tr key={`${item.id}-${index}`}>
              <DesktopTableColumn>
                {item.link && item.link?.url !== router.asPath ? (
                  <StyledLink type="link" link={item.link}>
                    {item.title}
                  </StyledLink>
                ) : (
                  <>{item.title}</>
                )}
              </DesktopTableColumn>
              {item.highlights.map((highlight) => (
                <DesktopTableHighlightColumn key={highlight.id}>{highlight.value}</DesktopTableHighlightColumn>
              ))}
              <td>
                <DownloadProductSheet
                  key={`${item.product.id}-download`}
                  link={item.downloadLink}
                  linkText={downloadText}
                  enableDirectProductSheetDownload={item.enableDirectProductSheetDownload}
                  product={item.product}
                  setOpenModal={setOpenModal}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </DesktopTable>

      <MobileContainer>
        {compareItems.map((item, index) => (
          <MobileColumn key={index}>
            <MobileColumnHeader>
              {item.link && item.link?.url !== router.asPath ? (
                <StyledLink type="link" link={item.link}>
                  {item.title}
                </StyledLink>
              ) : (
                <>{item.title}</>
              )}
            </MobileColumnHeader>
            <MobileDatalist>
              {item.highlights.map((highlight) => (
                <React.Fragment key={highlight.id}>
                  <MobileDatalistTitle>{highlight.label}</MobileDatalistTitle>
                  <MobileDatalistDescription>{highlight.value}</MobileDatalistDescription>
                </React.Fragment>
              ))}
            </MobileDatalist>
            <div>
              <DownloadProductSheet
                link={item.downloadLink}
                linkText={downloadText}
                enableDirectProductSheetDownload={item.enableDirectProductSheetDownload}
                product={item.product}
                setOpenModal={setOpenModal}
              />
            </div>
          </MobileColumn>
        ))}
      </MobileContainer>
    </Container>
  )
}

export default StandardProductCompare
