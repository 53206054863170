import React from 'react'
import StandardProductPropositionHeaderPattern from '@/patterns/StandardProductPropositionHeader'
import { ModalProps } from '../Modal/types'
import { StandardProductPropositionHeader as StandardProductPropositionHeaderProps, StoryblokLink } from '@/queries/schema.generated'

// TODO This should be moved to a bloks folder where all patterns are present.
// The components folder should only contain components (like buttons) that are used in patterns.

interface StandardProductDetailsCustomProps {
  propositionHeaderLink: StoryblokLink
  propositionHeaderLinkText: string
  propositions: {
    id: string
    title: string
    propositionLink: StoryblokLink
  }[]
}

export type StandardProductPropositionHeaderContentProps = StandardProductDetailsCustomProps & Pick<StandardProductPropositionHeaderProps, 'id' | 'component' | '_uid' | 'overviewSectionId' | 'product'>


interface Props {
  content: StandardProductPropositionHeaderContentProps
  setOpenModal: (props: ModalProps) => void
}

const StandardProductPropositionHeader: React.FC<React.PropsWithChildren<Props>> = ({ content, setOpenModal }) => {
  return <StandardProductPropositionHeaderPattern content={content} setOpenModal={setOpenModal} />
}

export default StandardProductPropositionHeader
